import { useRef, useState } from "react"


export const Dialog = ({title, message, btnText, callback})=>{
    const [show, setShow] = useState(true);
    return (
        <div className={`popup ${show ? "": "hidden"}`}>
            <strong>{title}</strong>
            <p>{message}</p>
            <button onClick={e=>{
                callback();
                setShow(false)
            }}>{btnText}</button>
        </div>
    )
}
export const Prompt = ({title, message, callback})=>{
    const [show, setShow] = useState(true);
    const inp = useRef(null);
    return (
        <div className={`popup ${show ? "": "hidden"}`}>
            <strong>{title}</strong>
            <p>{message}</p>
            <input ref={inp} type="text" id="prompt-result" />
            <div>
                <button onClick={e=> {
                    callback(false);
                    setShow(false);
                }}>Cancelar</button>
                <button onClick={e=> {
                    callback(inp.current.value);
                    setShow(false);
                }}>Aceptar</button>
            </div>
        </div>
    )
}
export const YN = ({title, message, callback})=>{
    const [show, setShow] = useState(true);
    return (
        <div className={`popup ${show ? "": "hidden"}`}>
            <strong>{title}</strong>
            <p>{message}</p>
            <div>
                <button onClick={e=> {
                    callback(false);
                    setShow(false);
                }}>No</button>
                <button onClick={e=> {
                    callback(true);
                    setShow(false);
                }}>Si</button>
            </div>
        </div>
    )
}