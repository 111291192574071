import { useEffect, useRef, useState } from "react";
import * as THREE from 'three'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';

/**
 * Stores atom animation suggestions
 * @param {string} newAnimation 
 */
const animStore = (newAnimation)=>{
    fetch('https://sanolivar.com/tommy/atom_anims.php',{
        method: "POST",
        body: JSON.stringify({ animation: newAnimation })
    });
}

let anim, setAnim;
let emoji, setEmoji;
let defaultA, defaultE;

let currentAnimClip = 1;

let animacionesGlobales;



const random = (min, max, not) => {
    if(not == undefined) return Math.floor(Math.random() * (max - min)) + min;
    let newRand = Math.floor(Math.random() * (max - min)) + min;
    while(newRand == not){
        newRand = Math.floor(Math.random() * (max - min)) + min;
    }
    return newRand;
}

export const updateAnimation = (animation, returnTo) => {
    if(anim == 'hablar' && animation == 'iniciar_habla') return;
    let included = false;
    for(let an of animacionesGlobales){
        if(an == animation) included = true;
    }
    // its an animation suggestion from AI
    if(!included){
        // store animation
        animStore(animation);
        // set callback animation as main animation
        animation = returnTo.toString();
        // remove callback animation
        returnTo = undefined;
    }
    setAnim(animation);
    defaultA = returnTo;
}

export const updateEmoji = (emo) =>{
    setEmoji(emo);
    updateAnimation('emoji', 'hablar')
    setTimeout(() => {
        setEmoji(defaultE);
    }, 5000);
}

export default ({ active, environment, model, animationRefs, defaultAnim, defaultEmoji, face, altFace, size }) => {
    animacionesGlobales = Object.keys(animationRefs);
    // defaultA = defaultAnim;
    defaultE = defaultEmoji;
    const mountRef = useRef(null);
    // clock element
    const CLOCK = new THREE.Clock();
    // animations stuff
    let AniMixer; // animation mixer
    let animations;
    [anim, setAnim] = useState(defaultAnim);
    [emoji, setEmoji] = useState(defaultEmoji);
    const rendRef = useRef(null);
    // face refs
    let emojiText;
    // texture loader
    const TexLoader = new THREE.TextureLoader();

    // renderer ref


    const emojiUpdate = ()=>{
        if(window.altFaceElement == undefined) return;
        if(window.altFaceElement.material.map) window.altFaceElement.material.map.dispose();
        if(window.altFaceElement.material) window.altFaceElement.material.dispose();
        if(emoji.includes('.png')){
            if(emojiText != undefined) emojiText.dispose();
            // load texture
            emojiText = TexLoader.load(emoji);
            emojiText.flipY = false;
            emojiText.needsUpdate = true;
            // update alternative face texture
            if(window.altFaceElement.material) window.altFaceElement.material.dispose();
            window.altFaceElement.material = new THREE.MeshBasicMaterial({map: emojiText});
        }else{
            // create canvas and draw emoji
            const canvas = document.createElement('canvas');
            const w = 320;
            const h = 320;
            canvas.width = w;
            canvas.height = h;
            // get canvas context
            const ctx = canvas.getContext('2d');
            // draw white background
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0,0,w,h);
            // draw emoji
            ctx.font = "240px serif";
            ctx.fillStyle = '#000000';
            ctx.textAlign = 'center';
            ctx.fillText(emoji, 160, 240);
            if(emojiText != undefined) emojiText.dispose();
            // load into alternative face
            emojiText = new THREE.CanvasTexture(canvas);
            emojiText.flipY = false;
            // emojiText.needsUpdate = true;
            if(window.altFaceElement.material) window.altFaceElement.material.dispose();
            window.altFaceElement.material = new THREE.MeshBasicMaterial({map: emojiText});
        }
    }

    // init render engine
    useEffect(() => {
        if(!active) return;
        // create scene
        const scene = new THREE.Scene();
        new RGBELoader().load(environment, (img) => {
            img.mapping = THREE.EquirectangularReflectionMapping;
            scene.environment = img;
            scene.environmentIntensity = 0.3;
        }, null, err => {
            console.error('Error while trying to load Environment Map:', err);
        })
        const scW = size == undefined ? 400: 800;
        const scH = size == undefined ? 500: 800;
        // Create camera
        const camera = new THREE.PerspectiveCamera(85, scW / scH, 0.1, 1000);
        camera.position.z = 0.8;
        camera.position.y = 0.3;
        camera.lookAt(new THREE.Vector3(0, 0.1, 0))

        // create directional light
        const dlight = new THREE.DirectionalLight(0xc499cc, 1);
        dlight.position.set(0, 1, 1.5);
        scene.add(dlight);
        // create and add ambient light
        const ambient = new THREE.AmbientLight(0xc499cc, 0.3)
        scene.add(ambient);

        // Create renderer
        const renderer = new THREE.WebGLRenderer({alpha: true});
        renderer.setSize(scW, scH);
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.BasicShadowMap;
        // set clear color
        renderer.setClearColor(0x000000, 0); // default to black
        mountRef.current.appendChild(renderer.domElement);
        rendRef.current = renderer;

        // load model
        const loader = new GLTFLoader();
        loader.load(model, gltf => {
            // add model into scene
            scene.add(gltf.scene)
            // set up model
            gltf.scene.traverse(node => {
                if (node.isMesh) {
                    // recalculate normals to prevent bugs
                    node.geometry.computeVertexNormals();
                    // activate shadows
                    node.castShadow = true;
                    node.receiveShadow = true;
                }
                // set alternative screen
                if (node.name == altFace) window.altFaceElement = node;
                // set default emoji
                emojiUpdate();
            })
            setTimeout(async () => {
                // set up animations
                AniMixer = new THREE.AnimationMixer(await gltf.scene);
                animations = await gltf.animations;
                currentAnimClip = random(0, animationRefs[anim].anims.length - 1);
                // let faceClip = animationRefs[anim].faces[random(0, animationRefs[anim].faces.length - 1)];
                let modelClip = animationRefs[anim].anims[currentAnimClip];
                let currentAnimation = THREE.AnimationClip.findByName(animations, modelClip);
                AniMixer.clipAction(currentAnimation).play();
                
                setTimeout(() => {
                    // randomize animation
                    AniMixer.addEventListener('loop', e =>{
                        AniMixer.clipAction(currentAnimation).stop();
                        let newAnimClip = 0;
                        if(animationRefs[anim].anims.length > 1) newAnimClip = random(0, animationRefs[anim].anims.length - 1, currentAnimClip);
                        // update oct 2024 face animations are included in model
                        // faceClip = animationRefs[anim].faces.length > 1 ? animationRefs[anim].faces[random(0, animationRefs[anim].faces.length - 1)]: animationRefs[anim].faces[0];
                        modelClip = animationRefs[anim].anims[newAnimClip];
                        currentAnimClip = Number(newAnimClip);
                        // model animation
                        currentAnimation = THREE.AnimationClip.findByName(animations, modelClip);
                        AniMixer.clipAction(currentAnimation).play();
                        // return to default animation
                        if(defaultA == undefined) return;
                        setAnim(defaultA.toString());
                        defaultA = undefined;
                    })
                }, 150);
            }, 100);
        }, null, err => {
            console.error("Error while trying to load model:", err);
        })

        const renderLoop = (currentTime) => {
            if (AniMixer != null) AniMixer.update(CLOCK.getDelta());
            rendRef.current.render(scene, camera);
            requestAnimationFrame(renderLoop);
        }  
        renderLoop();
        
        // clean and unmount
        return () => {
            if (mountRef != null && mountRef.current != null){
                mountRef.current.removeChild(renderer.domElement);
                // mountRef.current = null;
            }
            renderer.dispose();
        };
    }, []);

    
    useEffect(()=>{
        if(!active) return;
        if(AniMixer == undefined) return;
        // set up animations
        let modelClip = animationRefs[anim].anims[random(0, animationRefs[anim].anims.length - 1)];
        let currentAnimation = THREE.AnimationClip.findByName(animations, modelClip);

        AniMixer.stopAllAction();
        AniMixer.clipAction(currentAnimation).play();
    },[anim])

    useEffect(emojiUpdate,[emoji])
    if(!active) return (<></>);
    return (<div className="avatar" style={{pointerEvents: 'none'}} ref={mountRef} ></div>)
}