
const dialogs = {
    es:{
      fail: '¿Dijiste algo?. Recuerda primero decir ¡Átom!, en seguida estaré atento a tus preguntas. Estoy aqui para servirte',
      repeat: 'Lo siento, ¿Podrías repetir tu pregunta?'
    },
    en:{
      fail: `Do you said something?. Remember to tell "Atom!", then I will listen to your questions. I'm here to serve you`,
      repeat: "Apologize, Can you repeat your question?"
    },
    fr:{
      fail: `Tu as dit quelque chose ?. N'oublie pas de dire "Atom !", puis tu entendras un son... qui indique que j'écoute tes questions. Je suis là pour te servir`,
      repeat: "Excuse-moi, peux-tu répéter ta question ?"
    },
    it:{
      fail: `Hai detto qualcosa?. Ricordati di dire "Atom!", poi sentirai un suono... che indica che sto ascoltando le tue domande. Sono qui per servirti`,
      repeat: "Scusa, puoi ripetere la tua domanda?"
    },
    de:{
      fail: `Hast du etwas gesagt? Vergiss nicht, "Atom!" zu sagen, dann hörst du einen Ton ... der anzeigt, dass ich deinen Fragen zuhöre. Ich bin hier, um dir zu helfen`,
      repeat: "Entschuldige, kannst du deine Frage wiederholen?"
    },
    zh:{
      fail: `你说什么？记得说“嘿 Atom 然后你会听到一个声音…表示我正在听你的问题。我在这里为你服务`,
      repeat: `抱歉，你能重复一下你的问题吗`
    },
    ja: {
      fail: `「何か言いましたか? 「ヘイ アトム!」と伝えるのを忘れないでください。すると、私があなたの質問を聞いていることを示す音が聞こえます。私はあなたに奉仕するためにここにいます」`,
      repeat: `「申し訳ありません。質問をもう一度繰り返してもらえますか?」`
    }
  }

  
const transLangs = {
    // alex
    es: ['español', 'spanish', 'スペイン語', '西班牙語', 'spanisch', 'spagnolo', 'espagnol'],
    // christopher
    en: ['inglés', 'english', 'anglais', 'inglese', 'englisch', '英語', '英語'],
    // henri
    fr: ['francés', 'french', 'Französisch', 'francese', 'français', 'フランス語', '法語'],
    // giuseppe
    it: ['italiano', 'italian', 'italien', 'italienisch', '義大利語', 'イタリア語'],
    // florian
    de: ['alemán', 'german', 'deutsch', 'tedesco', 'allemand', 'ドイツ語', '德文'],
    // yunjhe
    zh: ['chino', 'chinese', 'chinois', 'cinese', 'chinesisch', '中國人', '中国語'],
    // keita
    ja: ['japonés', 'japanese', 'japanisch', 'giapponese', 'japonais', '日本語', '日本人']
  }


export {dialogs, transLangs};