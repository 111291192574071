/*
{
  command: {
    anims: animation variants
  }
}
*/
const animations = {
    aplaudir: {
      anims: ['applaud']
    },
    emocionado: {
      anims: ['excited']
    },
    saludar: {
      anims:  ['greetings']
    },
    frotar_manos:{
      anims: ['heat.hands']
    },
    idle:{
      anims:  ['idle']
    },
    saltar_de_emocion:{
      anims: ['jump']
    },
    oir_atentamente:{
      anims: ['listening']
    },
    negar_cabeza:{
      anims: ['no']
    },
    iniciar_habla:{
      anims: ['init.talk']
    },
    detener_habla:{
      anims: ['stop.talk']
    },
    hablar:{
      anims: ['talking1','talking2','talking3','talking4','talking5','talking6','talking7','talking8','talking1']
    },
    pulgar_arriba:{
      anims: ['thumb']
    },
    guiño:{
      anims: ['wink']
    },
    asombro:{
      anims: ['surprised']
    },
    asentir:{
      anims: ['yes']
    },
    rascarse_la_cabeza:{
      anims: ['head.scratch']
    },
    chocar_las_cinco:{
      anims: ['hi5']
    },
    abrazo:{
      anims: ['hug']
    },
    suplicar:{
      anims: ['suplicate']
    },
    pensativo:{
      anims: ['think']
    },
    prometer:{
      anims: ['promise']
    },
    emoji:{
      anims: ['emoji']
    },
  }

  export { animations }
  