import OpenAI from "openai";
import { cloudStorage, load, save } from "../../utils/Local";

const promptRoot = '/atom-assets/prompts/';

let ctx;

const checkpoints = /(\n)/gm;

let openai;
let currContext;
let MESSAGES;
const initAI = (key, prompt) => {
    // if (openai != undefined) return;
    openai = new OpenAI({ apiKey: key, dangerouslyAllowBrowser: true });
    fetch(`${promptRoot}${prompt}.txt`).then(r=> r.text()).then(data=> {
        ctx = prompt;
        currContext = [
            {
                role: 'system',
                content: data
            }
        ];
        load('chat-history').then(r=>{
            currContext = currContext.concat(r);
            reset();
        })
        reset();
    })
    console.log("AI Engine init success!")
}

const translatorURL = 'https://script.google.com/macros/s/AKfycbxZjPg2rlxIxffPVEpB9RWZZrdFax0-o1mhXiojgdIkUWZ_uK_13zGyJYQ5SD1YbW2b/exec?';

const predict = async (message, lang, callback) => {
    // uncomment lines below if you want to test something about voice synth or recognition or commands/emojis
    // return callback(`
    // ¡Hola! [saludar/] Soy **Átom** y estoy aquí para ayudarte. 😊 
    // Sanolivar cuenta con productos y servicios innovadores, principalmente en áreas como tecnología y soluciones IoT.[saltar_de_emocion/] 
    // Nuestros productos más destacados son: 
    // 📈 1. **Focus:** Una plataforma IoT de monitoreo en tiempo real y estadísticas, adaptable a múltiples sectores como agricultura, salud y transporte.
    // 🗺️ 2. **Location Services:** Una aplicación móvil que ofrece microlocalización, mapeo de interiores y realidad aumentada, facilitando la movilidad en grandes instalaciones.
    // 🤖 3. **Augmented Assistant:** Modelos de IA para aumentar la productividad laboral, facilitando desde la creación de contenido hasta el análisis de oportunidades.
    // Si deseas más información sobre alguno de estos productos, ¡estaré encantado de ayudarte! [guiño/]
    // Recuerda [asentir/], nuestros datos de contacto son muy importantes si decides que alguno de nuestros productos puede ser para ti. ¡Hablemos! 📞`);
    if (openai == undefined) return;
    // INSERT NEW MESSAGE
    MESSAGES.push({ role: 'user', content: ctx == 'tenjo'? message + 'en un parrafo': message });
    

    // just for voice creation
    // const sentences = [
    //     '¡Hola! [saludar/] Soy **Átom** y estoy aquí para ayudarte. 😊',
    //     'Sanolivar cuenta con productos y servicios innovadores, principalmente en áreas como tecnología y soluciones IoT.[saltar_de_emocion/]',
    //     'Nuestros productos más destacados son:',
    //     '📈 1. **Focus:** Una plataforma IoT de monitoreo en tiempo real y estadísticas, adaptable a múltiples sectores como agricultura, salud y transporte.',
    //     '🗺️ 2. **Location Services:** Una aplicación móvil que ofrece microlocalización, mapeo de interiores y realidad aumentada, facilitando la movilidad en grandes instalaciones.',
    //     '🤖 3. **Augmented Assistant:** Modelos de IA para aumentar la productividad laboral, facilitando desde la creación de contenido hasta el análisis de oportunidades.',
    //     'Si deseas más información sobre alguno de estos productos, ¡estaré encantado de ayudarte! [guiño/]',
    //     'Recuerda [asentir/], nuestros datos de contacto son muy importantes si decides que alguno de nuestros productos puede ser para ti. ¡Hablemos! 📞'
    // ];
    // let index = 0;
    // const intrvl = setInterval(() => {
    //     if(index >= sentences.length) return clearInterval(intrvl);
    //     callback(sentences[index]);
    //     index++;
    // }, 1000);
    // return;

    const sentimientoRegex = /<<sentimiento=[^>]+>>/gm;


    let response = await openai.chat.completions.create({
        messages: MESSAGES,
        model: "gpt-4o-mini",
        stream: true
    })

    let fullresponse = '';
    let responseChunk = '';
    let feeling;
    for await (let chunk of response){
        // retrieve openai chunk
        chunk = chunk.choices[0].delta.content;
        // validate chunk (last chunk is always = undefined)
        if(chunk == undefined) {
            if(lang == 'es') callback(responseChunk, true);
            else{
                fetch(translatorURL + `s=es&t=${lang}&q=${responseChunk}`).then(r => r.json()).then(d => callback(d.response, true));
            }
            responseChunk = '';
            break;
        }
        // update chunks and full response
        fullresponse += chunk;
        responseChunk += chunk;
        // on break points return built fragment
        if(chunk.match(checkpoints)){
            // retrieve and clean feeling
            if(responseChunk.match(sentimientoRegex)){
                feeling = responseChunk.match(sentimientoRegex)[0];
                responseChunk = responseChunk.replace(sentimientoRegex,'');
            }
            if(lang == 'es') callback(responseChunk);
            else fetch(translatorURL + `s=es&t=${lang}&q=${responseChunk}`).then(r => r.json()).then(d => callback(d.response));
            responseChunk = '';
        }
    }
    MESSAGES.push({
        role: 'assistant',
        content: fullresponse
    })
    // store interaction
    fullresponse = fullresponse.replace(sentimientoRegex,'');
    if(feeling != undefined) feeling = feeling.match(/([\w]+)/g)[1];
    else feeling = 'neutral';
    cloudStorage.storeMessage({user: message, atom: fullresponse}, feeling, ctx);
    // return full response for specific use cases
    if (lang != 'es') {
        let r = await fetch(translatorURL + `s=es&t=${lang}&q=${fullresponse}`);
        return await r.json().response;
    }
    else return fullresponse;
}

const reset = () => {
    MESSAGES = JSON.parse(JSON.stringify(currContext));
}

const setAIContext = (prompt) => {
    fetch(`${promptRoot}${prompt}.txt`).then(r=> r.text()).then(data=> {
        ctx = prompt;
        currContext = [
            {
                role: 'system',
                content: data
            }
        ];
        reset();
    })
}

export { initAI, predict, reset, setAIContext };