
const save = async (key, value)=>{
    // convert objects to string
    value = typeof(value) == 'object' ? JSON.stringify(value): value;
    // validate boolean 
    if(typeof(value) == 'boolean'){
        sessionStorage.setItem(`${key}-0`, value);
        return;
    }
    //split value into chunks
    value = value.match(/.{1,100000}/g);
    // store every chunk
    let lastindex;
    value.forEach((chunk, index) => {
        sessionStorage.setItem(`${key}-${index}`, chunk);
        lastindex = index;
    });
    let clean = false;
    while(!clean){
        lastindex++;
        let tmp = await sessionStorage.getItem(`${key}-${lastindex}`);
        if(await tmp == null){
            clean = true;
        }else sessionStorage.removeItem(`${key}-${lastindex}`);
    }
}

const load = (key)=>{
    return new Promise(async (resolve, reject)=>{
        let response = '';
        let finished = false;
        let chunk;
        let index = 0;
        while(!finished){
            chunk = await sessionStorage.getItem(`${key}-${index}`);
            if(chunk == undefined){
                finished = true;
                try {
                    resolve(JSON.parse(response));
                } catch (error) {
                    resolve(response);
                }
                break;
            }
            response += chunk;
            index++;
        }
    })
}

const cloud = 'https://sanolivar.com/api/atom.php?tk=apiuser';
const cloudAlt = 'https://sanolivar.com/api/atom-demo.php?tk=apiuser';
const cloudStorage = {
    getUserInfo: (email, callback, ctx)=>{
        const url = ctx == "sanolivar" ? cloud: cloudAlt;
        fetch(url+`&op=>get&m=${email}`)
            .then(r=> r.json())
            .then(json=> callback(json));
    },
    insertUser: async (email, tyc, mailing, ctx)=>{
        const url = `${ctx == "sanolivar" ? cloud: cloudAlt}&op=insert&m=${email}&t=${tyc}&r=${mailing}`;
        fetch(url)
            .then(r=> console.log(r.text()));
    },
    updateUser: (email, name, ctx)=>{
        const url = ctx == "sanolivar" ? cloud: cloudAlt;
        fetch(url+`&op=>update&m=${email}&n=${name}`)
            .then(r=> r.json())
            .then(json=> callback(json));
    },
    storeMessage: (messages, feeling, ctx)=>{
        messages = JSON.stringify(messages);
        const url = ctx == "sanolivar" ? cloud: cloudAlt;
        // retrieve user email
        load('user_email').then(response => {
            const data = {
                m: messages,
                f: feeling,
                u: response
            }
            fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(r=> r.json());
        })
    }
}

export {
    load,
    save,
    cloudStorage
}