
export const VoiceManager = class {

    // voice synthesis instance
    #speaker;
    // voice synthesis status
    #playing = false;
    
    // voice recognition status
    #runing = false;
    // last recognition initialized
    #activeRecognition;

    // name of the entity
    #NAME;
    // voices
    #voices;
    // custom functions
    #tools;

    // current lang
    #lang;
    // error listening callback strings
    #sentences;

    constructor() {
        this.mayInclude = (array, param) => {
            let resp = false;
            array.forEach(element => {
                if (param.includes(element)) {
                    resp = true;
                }
            });
            return resp;
        }
    }

    GRAMMARS;
    EXCLUSIONS;
    async config(lang, name, exclusions, voices, commands, strings, dict) {
        this.#lang = lang;
        // add grammars
        this.GRAMMARS = `#JSGF V1.0; grammar personalizado; public <personalizado> = hey | hola | IA | `;
        for (let nm of name) {
            this.GRAMMARS += (nm + ' | ');
        }
        for (let dic of dict) {
            this.GRAMMARS += (dic + ' | ');
        }
        this.GRAMMARS += ';';
        // setup exclusions
        this.EXCLUSIONS = `#JSGF V1.0; grammar personalizado; public <personalizado> = `;
        for (let exc of exclusions) {
            this.EXCLUSIONS += (exc + ' | ');
        }
        this.EXCLUSIONS += ';';
        this.#voices = voices;
        this.#NAME = name;
        this.#tools = commands;
        this.#sentences = strings;
        let finished = false;
        while(!finished){
            this.vcs = speechSynthesis.getVoices();
            if(this.vcs != undefined && this.vcs != null) finished = true;
        }
    }

    setLang(lang) {
        this.#lang = lang;
    }

    /* *******************************************************************************************
    *************************************************** SYNTHESIS BLOCK **************************
    **********************************************************************************************/

    /**
     * Reproducir por voz el texto provisto
     * @param {*} message El texto que se desea sintetizar
     * @param {*} forced Si hay una reproduccion en curso, detenerla y forzar la nueva
     * @param {*} speed Velocidad de reproduccion
     */
    play(message, lang, callback, forced, speed) {
        // if (!this.#playing || forced) {
        message = message.replaceAll("*", "").replaceAll("-", "").replaceAll("#", "").replace(/(?:\[.*?\])|(?:[\uD800-\uDBFF])/gm, "");
        // stop previous playing
        if (this.#playing && forced) speechSynthesis.cancel();
        // create voice
        this.#speaker = new SpeechSynthesisUtterance(message);
        this.#speaker.voice = this.#voices[lang];
        if(this.#voices[lang] == undefined){
            switch(lang){
                case 'en':
                    for(let v of this.vcs){
                        if(v.name == 'Microsoft Mark - English (United States)') {
                            this.#speaker.voice = v;
                            break;
                        }
                    }
                    break;
                case 'es':
                    for(let v of this.vcs){
                        if(v.name == 'Microsoft Pablo - Spanish (Spain)') {
                            this.#speaker.voice = v;
                            break;
                        }
                    }
                    break;
                    
            }
        }
        this.#speaker.rate = speed == undefined ? 1.4 : speed;

        // update playing status
        this.#speaker.addEventListener('end', e => {
            if (e.charIndex === 0) {
                callback();
            }
        });

        this.#playing = true;
        setTimeout(() => {
            // play voice
            speechSynthesis.speak(this.#speaker);
        }, 500);
        // }
    }


    /**
     * Play Entity sound
     */
    playSound(path) {
        let audio = document.createElement('audio'); // Create a audio element using the DOM
        audio.currentTime = 0;
        audio.style.display = "none"; // Hide the audio element
        // audio.src = '/atom-listening.mp3'; // Set resource to our URL
        audio.src = path; // Set resource to our URL
        audio.autoplay = true; // Automatically play sound
        audio.onended = (e) => {
            let audios = document.querySelectorAll('audio');
            audios[audios.length - 1].remove();
        };
        document.body.appendChild(audio);
        audio.play();
    }

    /**
     * Stops current playing voice
     */
    stop() {
        this.#playing = false;
        speechSynthesis.cancel();
    }

    /* *******************************************************************************************
    *************************************************** RECOGNITION BLOCK ************************
    **********************************************************************************************/

    #initRecognizer(onstart, onerror) {
        // add custom grammars
        const speechRecognitionList = new webkitSpeechGrammarList();
        for (let i = 1; i < 100; i++) {
            speechRecognitionList.addFromString(this.GRAMMARS, i);
            speechRecognitionList.addFromString(this.EXCLUSIONS, 0);
        }
        // init voice recognition
        const reco = new webkitSpeechRecognition();
        reco.grammar = speechRecognitionList;
        reco.lang = this.#lang;
        reco.onstart = onstart;
        reco.onerror = onerror;
        reco.continuous = true;
        reco.interimResults = false;
        reco.onend = e=> {
            if(this.#runing) {
                reco.start();
            }
        };
        // store
        this.#activeRecognition = reco;
        return reco;
    }
    /**
     * Initialize active listening
     * @param {function} callback Function called after voice recognition stops
     * @param {function} listeningCallback functiont executed when key word is detected
     * @param {function} failCallback Function called when speech detected doesn't includes the keyword
     */
    activeListening(callback, listeningCallback, predictCallback) {
        if (this.#runing) return;
        // config recognizer
        let reco = this.#initRecognizer(
            // on start
            () => {
                console.log("active listening initialized")
                this.#runing = true;
            },
            // on error
            (err) => {
                this.#activeRecognition.stop();
                this.#runing = false;
                setTimeout(() => {
                    this.activeListening(callback, listeningCallback, predictCallback);
                }, 50);
            }
        );
        // start active listening
        reco.start();
        // add result listener
        reco.addEventListener('result', result => {
            let matching = false, forced= false;
            // retrieve recognition results
            result = result.results;
            // retrieve previous result
            let prev = result.length > 1 ? result[result.length - 2][0].transcript.toLowerCase(): '';
            // retrieve last result
            result = result[result.length - 1][0].transcript.toLowerCase();
            // validate avatar name is included
            if (this.mayInclude(this.#NAME, result)) matching = true;
            // validate interruption by checking its just the name
            if (this.#NAME.includes(result.toLowerCase().replaceAll('.', ''))) forced = true;
            // validate interruption with full phrase
            if (this.#playing && this.mayInclude(this.#NAME, result)) forced = true;
            if(forced) listeningCallback();
            if(!matching){
                prev = this.#NAME.includes(prev.toLowerCase().replaceAll('.', ''));
                if(prev) matching = true;
            }
            if(matching) this.handleResult(result, callback, predictCallback);
        })
    }
    
    async handleResult(result, cb, pcb){
        // validate commands
        let cmmnd;
        for (let cm of Object.keys(this.#tools)) {
            if (result.toLowerCase().includes(cm)) cmmnd = cm;
        }
        // execute commands
        if (cmmnd != undefined) {
            console.log('executing command')
            let command = this.#tools[cmmnd];
            try {
                pcb(await command(result));
            } catch (error) {
                console.log(error);
            }
            return;
        }
        cb(result);
    }

    /**
     * Stop active listening
     */
    stopReco() {
        try {
            this.#runing = false;
            this.#activeRecognition.stop();
        } catch (error) {
        }
    }

    

    /**
     * Stop all activity
     */
    stopEngine() {
        this.stop();
        this.stopReco();
    }
}
